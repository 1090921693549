export const ROOT = "/";
export const SIGNIN = "/signin";
export const MY_ACCOUNT = "/my-account";
export const ROUTE404 = "/404";
export const COURIERS = "/couriers";
export const COURIER = "/courier";
export const TEMPLATES = "/templates";
export const CONFIGURATIONS = "/configurations";
export const EMAILS = "/emails";
export const EMAIL = "/email";
export const TAGS = "/tags";
