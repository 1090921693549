// routes
import {
  MY_ACCOUNT,
  COURIERS,
  EMAILS,
  CONFIGURATIONS,
  TAGS,
} from "constants/routes";

import AccountCircle from "@material-ui/icons/AccountCircleTwoTone";
import Email from "@material-ui/icons/EmailTwoTone";
import Send from "@material-ui/icons/SendTwoTone";
import LocalOfferIcon from "@material-ui/icons/LocalOfferTwoTone";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplicationsTwoTone";

export const menu = [
  {
    icon: Email,
    label: "Courrier",
    path: COURIERS,
    divider: true,
  },
  // {
  //   icon: AllInboxIcon,
  //   label: 'Templates',
  //   path: TEMPLATES,
  //   divider: true,
  // },
  {
    icon: SettingsApplicationsIcon,
    label: "Configurations",
    path: CONFIGURATIONS,
    divider: true,
  },
  {
    icon: Send,
    label: "Sent",
    path: EMAILS,
    divider: true,
  },
  {
    icon: LocalOfferIcon,
    label: "Tags",
    path: TAGS,
    divider: true,
  },
  {
    icon: AccountCircle,
    label: "My Account",
    path: MY_ACCOUNT,
    divider: true,
  },
];
