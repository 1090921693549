export const routeNames = [
  "Signin",
  "MyAccount",
  "Route404",
  "Couriers",
  "Courier",
  "Templates",
  "Configurations",
  "Emails",
  "Email",
  "Tags",
];
